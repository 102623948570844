<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="checkForm"
  >
    <v-row>
      <h5 class="text-center"><strong>{{ $t('message.two_factor_authentication_reset_title') }}</strong></h5>

      <br>
      <br>

      <p class="text-sm-body-1">{{ $t('message.two_factor_authentication_reset_introduction') }}</p>

      <v-col cols="12" sm="12" md="12">
        <p v-if="successMessage">
          <v-alert
            text
            dense
            color="teal"
            border="left"
            type="success"
          >
            {{ successMessage }}
          </v-alert>
        </p>
        <p v-if="errors.length">
          <v-alert
            text
            dense
            type="error"
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.title }}
          </v-alert>
        </p>

        <v-text-field
          ref="email"
          autocomplete="username"
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          @input="checkEmail()"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <router-link to="/connexion" class="recovery mt-2 text-left">{{ $t('message.back_to_login') }}</router-link>
    </v-row>

    <button type="submit" :disabled="!valid" class="btn btn-connect notranslate" :class="valid ? 'btn-primary' : ''">
      {{ $t('message.two_factor_authentication_reset_submit') }}
    </button>
  </v-form>
</template>

<script>
import LoginLayout from '../../layouts/LoginLayout'
import Vue from 'vue'
import config from '../../config'

export default {
  name: 'LostQrCode',
  data: () => ({
    valid: false,
    successMessage: null,
    errors: [],
    email: '',
    emailRules: [
      v => !!v || this.$t('message.mandatory_email'),
      v => /.+@.+\..+/.test(v) || this.$t('message.mandatory_format_valid_email')
    ]
  }),
  created () {
    this.$emit('update:layout', LoginLayout)
    document.title = config.title + ' - ' + this.$t('message.two_factor_authentication_reset_title')
  },
  computed: {},
  methods: {
    checkEmail () {
      if (/.+@.+\..+/.test(this.email)) {
        this.valid = true
      } else {
        this.valid = false
      }
    },
    checkForm () {
      if (this.valid) {
        this.lostQrCode()
      }
    },
    lostQrCode () {
      const data = {
        email: this.email.toLowerCase()
      }

      const formData = new FormData()
      formData.append('data', JSON.stringify(data))

      Vue.prototype.$http
        .post(config.apiUrl + '/lost-qr-code', formData)
        .then(response => {
          if (response.status === 200) {
            this.successMessage = response.data
          }
        })
        .catch(e => {
          const response = e.response

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
            this.successMessage = null
          }
        })
    }
  }
}
</script>
